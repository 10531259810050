<script setup>
import { orderInfo } from "../../api/index"
import resultSrc from '../../assets/result.svg'
import { ref, onMounted, watch, onBeforeUnmount } from 'vue'


const orderId = ref(localStorage.getItem('orderId'))

const info = ref({
    store: {},
    order: {}
})

const num = ref(1)

let timer = setInterval(() => {
    num.value++
    getInfo({
        orderId: orderId.value
    })
}, 3000)


onMounted(() => {
    document.title = '支付结果'

    getInfo({
        orderId: orderId.value
    })
})

onBeforeUnmount(() => {
    clearInterval(timer)
})



const getInfo = async (params) => {
    const res = await orderInfo(params)
    info.value = res
    if (res.order.orderPaymentStatus == 1) {
        clearInterval(timer)
    }


}


watch(num, (val) => {
    if (val > 2) {
        clearInterval(timer)
    }
})



const handler = () => {
    getInfo({
        orderId: orderId.value
    })
    num.value = 1
    clearInterval(timer)
    timer = setInterval(() => {
        num.value++
        getInfo({
            orderId: orderId.value
        })
    }, 3000)

}


const formattedDateFn = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    return formattedDate
}

</script>

<template>
    <div class="box">
        <p class="img">
            <img :src="resultSrc" alt="">
        </p>
        <p class="name">
            {{ info.order.orderPaymentStatus == 1 ? '支付成功 ¥' + info.order.actualPaymentAmount : '支付中，请稍等' }}
        </p>
        <div class="contant-box">
            <ul class="contant">
                <li>
                    <span>门店名称</span>
                    <span class="right">{{ info.store.storetag }}</span>
                </li>
                <li>
                    <span>支付方式</span>
                    <span class="right" v-if="info.order.paymentMethod == 1">微信</span>
                    <span class="right" v-else-if="info.order.paymentMethod == 2">余额支付</span>
                    <span class="right" v-else-if="info.order.paymentMethod == 3">组合支付</span>
                    <span class="right" v-else-if="info.order.paymentMethod == 4">支付宝</span>
                </li>
                <li v-if="info.order.paymentTime">
                    <span>支付时间</span>
                    <span class="right">{{ formattedDateFn(info.order.paymentTime) }}</span>
                </li>
                <li style="border-bottom: none;">
                    <span>支付单号</span>
                    <span class="right">{{ info.order.orderNumber }}</span>
                </li>
            </ul>
        </div>

        <div class="btn-box" v-if="info.order.orderPaymentStatus != 1">
            <div class="btn" @click="handler">
                我已完成支付
            </div>
        </div>
    </div>
</template>

<style scoped>
.box {
    background-color: #F5F5F5;
    width: 100vw;
    font-size: 14px;
    box-sizing: border-box;
    overflow: hidden;
}

.img {
    text-align: center;
    margin-top: 24px;
}

.img img {
    width: 57px;
    height: 57px;
    border-radius: 50%;
}

.name {
    text-align: center;
    margin-top: 12px;
    font-weight: bold;
    font-size: 16px;
}

.contant-box {
    padding: 16px;
    margin-bottom: 16px;
}

.contant {
    padding: 0 16px;
    background-color: #fff;
    border-radius: 12px;
}

.contant li {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.right {
    color: rgba(153, 153, 153, 1);
}

.btn-box {
    padding: 0 16px;
}

.btn {
    height: 52px;
    line-height: 52px;
    background-color: #CDB58C;
    border-radius: 26px;
    color: #fff;
    text-align: center;
}
</style>