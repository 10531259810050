import http from './request';
// 获取商户名称
export function getInfo(params) {
  return http('/api/polymerize/merchant/store',{
    params: params
  });
}


// 创建订单
export function createOrder(params) {
  return http('/api/polymerize/order/create', {
    method: 'POST',
    params: params,
  });
}


// 订单详情
export function orderInfo(params) {
  return http('/api/polymerize/order/info',{
    params: params
  });
}


// 支付宝支付
export function aliPay(params) {
  return http('/api/polymerize/alipay/wap', {
    method: 'POST',
    params: params,
  });
}

// 微信支付
export function wxPay(params) {
  return http('/api/polymerize/wechat/wap', {
    method: 'POST',
    params: params,
  });
}

// 微信支付
export function wxPayNative(params) {
  return http('/api/polymerize/wechat/native', {
    method: 'POST',
    params: params,
  });
}

// 获取商户名称
export function getCodeInfo(params) {
  return http('/api/polymerize/wechat/token',{
    params: params
  });
}


// 微信支付jsapi
export function wxJsApi(params) {
  return http('/api/polymerize/wechat/jsapi', {
    method: 'POST',
    params: params,
  });
}