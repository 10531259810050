<script setup>

import { getInfo, createOrder, aliPay, wxPayNative, getCodeInfo, wxJsApi } from "../../api/index"
import { ref, reactive, onMounted, watch, nextTick, onBeforeMount } from 'vue'


let payMethod = ref('')
let paymentMethod = ref(4)
let storeId = ref('')
let merchantId = ref('')
let merchantInfo = ref('')
const cursor = ref(true)
const cursorLeft = ref('0px')
const spanRef = ref(null)
const openId = ref(null)
const wxconfigInfo = ref(null)
const loading = ref(false)
const pageShow = ref(false)
const money = ref('');
const thirdPartyId = ref('')
const defPrice = ref('')
const getStoreId = () => {
    if (document.URL.indexOf('?') > -1) {
        let urlString = document.URL.split('?')[1];
        let obj = {};
        urlString.split('&').forEach(item => {
            let [key, value] = item.split('=');
            obj[key] = value;
        })
        storeId.value = obj.storeId
        if (isNumber(obj?.price)) {
            money.value = obj.price
            defPrice.value = obj.price
        }
        if (obj?.thirdPartyId) {
            thirdPartyId.value = obj.thirdPartyId
        }

        initName({ storeId: storeId.value })

    }
}

const getCode = () => {
    if (document.URL.indexOf('?') > -1) {
        let urlString = document.URL.split('?')[1];
        let obj = {};
        urlString.split('&').forEach(item => {
            let [key, value] = item.split('=');
            obj[key] = value;
        })
        const hasCode = 'code' in obj
        if (hasCode) {
            return obj.code
        } else {
            return ''
        }
    }
}

const isNumber = (str) => {
    return !isNaN(parseFloat(str))
}

const initName = async (params) => {
    const res = await getInfo(params)
    merchantInfo.value = res
    merchantId.value = res.merchantid
    pageShow.value = true
}
onBeforeMount(() => {
    getStoreId()
}),

    onMounted(() => {
        payMethod.value = localStorage.getItem('info')
        paymentMethod.value = localStorage.getItem('info') == 'wx' ? 1 : 4
        if (paymentMethod.value == 1) {
            const openIdLoc = localStorage.getItem('openId')
            const code = getCode()
            if (openIdLoc) {
                openId.value = openIdLoc
            } else {
                if (code) {
                    getOpenId({
                        code
                    })
                } else {
                    winRedirect()
                }

            }
        }
        document.title = '向商家付款'
        setInterval(() => {
            cursor.value = !cursor.value
        }, 400)

    })

const getOpenId = async (params) => {
    const res = await getCodeInfo(params)
    localStorage.setItem('openId', res)
    openId.value = res
    console.log(res, '这是openid')
}


const themeVars = reactive({
    NumberKeyboardButtonBackground: '#E8E8E8',
    NumberKeyboardKeyFontSize: '28px'
});



const canPay = ref(false)
watch(money, (val) => {
    if (defPrice.value) {
        money.value = defPrice.value
    }

    if (val.indexOf('.') > -1) {
        const arr = val.split('.')
        if (arr[1].length >= 2) money.value = arr[0] + '.' + arr[1].substring(0, 2)
    }

    if (val.length > 1 && val.indexOf('0') == 0 && val[1] != '.') {
        money.value = val[1]
    }

    if (val == '0.00') {
        money.value = '0'
    }

    if (val.indexOf('.') == 0) {
        money.value = '0.'
    }

    if (val.length > 24) {
        money.value = val.slice(0, 24)
    }

    if (val && Number(val) != 0) {
        themeVars.NumberKeyboardButtonBackground = '#CDB58C'
        canPay.value = true
    } else {

        themeVars.NumberKeyboardButtonBackground = '#E8E8E8'
        canPay.value = false
    }


    nextTick(() => {
        if (spanRef.value) {
            cursorLeft.value = spanRef.value.offsetWidth + 'px'
        }
    })
})


watch(spanRef, (val) => {
    cursorLeft.value = val.offsetWidth + 'px'
})

const onInp = (params) => {
    if (params == '.' && money.value.indexOf('.') > -1) {
        const old = money.value
        nextTick(() => {
            money.value = old
        })
    }
}






const handlerPay = () => {
    if (!loading.value) {
        canPay.value ? createOrderInfo() : ''
    }
}



const orderId = ref('')

const createOrderInfo = async () => {
    loading.value = true
    const params = {
        "storeId": storeId.value,
        "price": Number(money.value).toFixed(2) * 100,
        paymentMethod: paymentMethod.value,
        "remark": "",
        thirdPartyId: thirdPartyId.value ? Number(thirdPartyId.value) : 0
    }

    const res = await createOrder(params)
    orderId.value = res.orderId
    localStorage.setItem('orderId', res.orderId)

    paymentMethod.value == 1 ? wxPayment() : aliPayment()
}



const aliPayment = async () => {
    const url = document.URL
    const arr = url.split('#')

    const returnUrl = 'https://polymerize.xiaojianren365.com/result'

    const params = {
        orderId: orderId.value,

        quitUrl: url
    }
    const res = await aliPay(params)
    loading.value = false
    const resData = res
    const div = document.createElement('div')
    div.id = 'alipay'
    div.innerHTML = resData
    document.body.appendChild(div)
    document.querySelector('#alipay').children[0].submit()

}



const winRedirect = () => {
    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe1e9f583bfe8023a&redirect_uri=https://polymerize.xiaojianren365.com?storeId=' + storeId.value + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'

}

const wxPayment = async () => {
    const returnUrl = 'https://polymerize.xiaojianren365.com/result'

    const params = {
        orderId: orderId.value,
        suOpenId: openId.value
    }
    const res = await wxJsApi(params)
    loading.value = false
    wxconfigInfo.value = res

    weixinPay()

}


// 解决微信内置对象报错
const weixinPay = (data) => {
    var vm = this;
    if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', vm.onBridgeReady(data), false);
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', vm.onBridgeReady(data));
            document.attachEvent('onWeixinJSBridgeReady', vm.onBridgeReady(data));
        }
    } else {
        onBridgeReady();
    }
}



const onBridgeReady = () => {
    var vm = this;
    WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        debug: true,
        "appId": wxconfigInfo.value.appId,
        "timeStamp": wxconfigInfo.value.timeStamp + '',
        "nonceStr": wxconfigInfo.value.nonceStr,
        "package": wxconfigInfo.value.packageVal,
        "signType": wxconfigInfo.value.signType,
        "paySign": wxconfigInfo.value.paySign,
        jsApiList: [
            'chooseWXPay'
        ]
    },
        function (res) {

            if (res.err_msg == "get_brand_wcpay_request:ok") {
                window.location.href = 'https://polymerize.xiaojianren365.com/result'
            } else {
                loading.value = false
            }
        }
    );
}


</script>

<template>
    <div class="box">

        <div v-if="pageShow">
            <div class="img">
                <div class="relative" v-if="merchantInfo.backimage">
                    <img :src="merchantInfo.backimage" alt="" class="img-bg" style="border-radius: 50%;" />
                </div>
                <div class="relative" v-else>

                    <img :src="require('../../assets/round.png')" alt="" class="img-bg" />
                    <img :src="require('../../assets/home.png')" alt="" class="img-home" />
                </div>
            </div>
            <p class="name" :style="{ visibility: merchantInfo.storetag ? 'visible' : 'hidden' }">
                {{ merchantInfo.storetag ? merchantInfo.storetag : '1' }}
            </p>


            <div class="contant-box">
                <div class="contant">
                    <p class="title">付款金额</p>
                    <div class="con-inp">
                        <div class="rmb">¥</div>
                        <div style="position: relative;width: 100%;">
                            <input v-model="money" placeholder="请输入付款金额" class="inp" readonly="readonly"
                                style="border: none;" />
                            <div class="cursor" :style="{ display: cursor ? 'block' : 'none', left: cursorLeft }"></div>
                        </div>
                    </div>
                    <p class="tip">为保障您的资金安全，请核实交易信息</p>
                </div>
            </div>


            <div class="contant-box">
                <div class="contant flex">
                    <div class="pay-method">
                        支付方式
                    </div>



                    <div v-if="payMethod === 'wx'">
                        <img :src="require('../../assets/wx.svg')" alt="" class="method-img">
                        <span class="middle">微信</span>
                    </div>
                    <div v-else="payMethod === 'ali'">
                        <img :src="require('../../assets/ali.svg')" alt="" class="method-img">
                        <span class="middle">支付宝</span>
                    </div>

                </div>
            </div>


            <span class="em-span" ref="spanRef">{{ money }}</span>


            <van-config-provider :theme-vars="themeVars">
                <van-number-keyboard v-model="money" :show="true" theme="custom" extra-key="." close-button-text="支付"
                    @close="handlerPay" :class="{ 'disable': merchantId == '' }" @input="onInp" />
            </van-config-provider>
        </div>
        <div v-else style="width: 100vw; height: 100vh; ">
            <div style=" position: absolute; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%);">
                <van-loading type="spinner" color="#CDB58C" />
            </div>



        </div>



    </div>
</template>

<style scoped>
.cursor {
    position: absolute;

    top: 2px;
    width: 0;
    height: 24px;
    border-left: 1px solid #CDB58C;
}

.box {
    background-color: #F5F5F5;
    width: 100vw;
    font-size: 14px;
    box-sizing: border-box;
    overflow: hidden;
}

.img {
    text-align: center;
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.img-bg {
    width: 57px;
    height: 57px;
}

.img-home {
    width: 33px;
    height: 33px;
    position: absolute;
    top: 13px;
    left: 13px;
}

.relative {
    width: 57px;
    height: 57px;
    position: relative;
}


.name {
    text-align: center;
    margin-top: 12px;
    font-weight: bold;
    font-size: 16px;
}

.contant-box,
.contant {
    padding: 16px;
}

.contant {
    background-color: #fff;
    border-radius: 12px;
}

.con-inp {
    margin: 16px 0;
    padding-bottom: 4px;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    justify-content: start;
}

.inp {
    font-size: 22px;
    padding: 0;
    width: 100%;

}

.rmb {
    font-size: 22px;
    line-height: 28px;
    margin-right: 4px;
}


.tip {
    font-size: 12px;
    color: #BBBBBB;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.method-img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.disable {
    pointer-events: none;
}

.middle {
    vertical-align: middle;
}

.pay-method {
    line-height: 24px;
}

.em-span {
    font-size: 22px;
    position: relative;
    z-index: -10;
    visibility: hidden;
}

input::placeholder {
    color: #BBBBBB;
}
</style>