import axios from 'axios';
import { showToast } from 'vant';
import 'vant/es/toast/style'
// 创建新的axios实例
const service = axios.create({
    baseURL: 'https://polymerizeapi.xiaojianren365.com',
    // 超时时间暂定5s
    timeout: 6000,
  });

  service.interceptors.request.use(
    config => {
        // 此处添加Loading
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );


service.interceptors.response.use(
    response => {
      if(response.data.status == 200) {
        return response.data
      }else {
        showToast(response.data.message);
      }

      
    },
    error => {
      // closeToast();
      // 处理异常情况，根据项目实际情况处理或不处理
      if (error && error.response) {
        // 根据约定的响应码处理
        switch (error.response.status) {
          case 403:
            error.message = '拒绝访问';
            break;
          case 502:
            error.message = '服务器端出错';
            break;
          default:
            error.message = `连接错误${error.response.status}`;
        }
      } else {
        // 超时处理
        error.message = '服务器响应超时，请刷新当前页';
      }
      // showToast(error.message);
      return Promise.resolve(error.response);
    }
  );

  

const Request = (url, options = {}) => {
    let method = options.method || 'get';
    let params = options.params || {};
    if (method === 'get' || method === 'GET') {
      return new Promise((resolve, reject) => {
        service
          .get(url, {
            params: params,
          })
          .then(res => {
            if (res && res.data) {
              resolve(res.data);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        service
          .post(url, params)
          .then(res => {
            if (res && res.data) {
              resolve(res.data);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  };

 
export default Request;
