import { createRouter,createWebHistory } from "vue-router";
import Home from '../pages/mode/index.vue'
import Result from '../pages/result/index.vue'



const routes = [
    {
      path:'/home',
      name:Home,
      component:Home
    },
    {
      path:'/result',
      component:Result
    },

    {
      path:'/',
      redirect:'/home'
    }
  ]

  const router = createRouter({
    history:createWebHistory(),
    routes
  })

  export default router
